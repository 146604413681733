<template>
  <section class="home-view container">
    <Headline :size="1">
      Welcome back, {{ name }}!
    </Headline>

    <div class="cards-wrapper">
      <div class="cards-row">
        <Card
            v-if="$store.getters['user/haveAccessToAccreditation']"
            class="cards-item"
            iconName="fma-accreditation"
            title="Accreditation Request"
            @click="redirect('dashboard/request-accreditation')"
        />
        <Card
            v-if="$store.getters['user/haveAccessToTickets']"
            class="cards-item"
            iconName="fma-ticket"
            title="Ticket Request"
            @click="redirectToNamedRoute(routerNames.IndexRoutes.DashboardRoutes.TicketRequest)"
        />
        <Card
            v-if="$store.getters['user/haveAccessToActivations']"
            class="cards-item"
            iconName="fma-partner"
            title="Activation Request"
            @click="redirectToNamedRoute(routerNames.IndexRoutes.DashboardRoutes.SponsorActivation)"
        />
        <Card
            v-if="$store.getters['user/haveAccessToBroadcasterBooking']"
            class="cards-item"
            iconName="fma-radar"
            title="Broadcaster Booking"
            @click="openTabWithUrl($store.getters['user/getBroadcasterBookingUrl'])"
        />
        <Card
            v-if="$store.getters['user/haveAccessToApprovals']"
            class="cards-item"
            iconName="fma-approval"
            title="Approvals"
            @click="openTabWithUrl('https://theafg.sharepoint.com/sites/BrandInformationCentre46/')"
        />
        <Card
            v-if="$store.getters['user/haveAccessToBrandAccess']"
            class="cards-item"
            iconName="fma-brandassets"
            title="Information Centre"
            @click="openTabWithUrl('https://theafg.sharepoint.com/sites/BrandInformationCentre46/')"
        />

      </div>
    </div>
  </section>
</template>

<script>
import RouterNames from '@/router/route.names';
import Headline from '@/components/Headline/Headline.component'
import Card from '@/components/Card/Card.component'

import get from "lodash.get";

export default {
  name: "Home",
  components: {
    Headline,
    Card
  },
  props: {
    imgUrl: String,
    text: String
  },
  computed: {
    name() {
      return get(this, '$store.state.user.model.name')
    },
    routerNames() {
      return RouterNames
    }
  },

  methods: {
    redirect(path) {
      this.$router.push(path)
    },
    redirectToNamedRoute(name) {
      this.$router.push({name});
    },
    openTabWithUrl(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.home-view {
  padding: 0;

  .cards-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .cards-item {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .cards-dummy {
    display: block;
    width: 220px;
    height: 0;
  }

  ::v-deep .paragraph-component {
    padding: 0;
  }
}
</style>
