<template>
  <component :is="'h' + level"
    class="headline-component"
    :class="{[`headline-size-${size}`]: true}"
  >
    <b-icon
      id="tooltip-target-1"
      v-if="icon"
      class="headline-component__icon"
      :style="{fontSize: `${32-(size*4)}px`}"
      :icon="icon"
    />
    <b-tooltip
      v-if="tooltipText"
      target="tooltip-target-1" triggers="hover" placement="bottom"
      :title="tooltipText"
    />
    <slot />
    <span class="hint" v-if="hint">{{hint}}</span>
  </component>
</template>

<script>
import inRange from 'lodash.inrange';

export default {
  name: "DDMCAF-Headline",
  props: {
    icon: {
      type: String,
    },
    tooltipText: {
      type: String,
    },
    size: { // 1, 2 , 3, 4, 5
      type: [Number, String],
      default: 0,
      validator: value => inRange(value, 0, 6)
    },
    hint: {
      type: String,
    },
    level: {
      type: [Number, String],
      default: 1,
    }
  }
}
</script>

<style lang="scss" scoped>
  .headline-component {
    position: relative;
    font-weight: bold;
    cursor: default;

    &__icon {
      color: red;
      position: absolute;
      max-height: 25px;
      left: -1.5em;
      top: -1px;
    }
  }

  .headline-size-1 {font-size: 34px; margin-bottom: 32px;}
  .headline-size-2 {font-size: 26px; margin-bottom: 24px;}
  .headline-size-3 {font-size: 20px; margin-bottom: 24px;}
  .headline-size-4 {font-size: 16px; margin-bottom: 24px;}
  .headline-size-5 {font-size: 12px; margin-bottom: 12px;}
  .hint { color: #9b9b9b;}
</style>
