<template>
  <div
    class="card-component"
    @click="emmitClick"
  >
    <Icon
        v-if="iconName"
        class="button-component__icon"
        :icon="iconName"
        size="120px"
    />
      <Headline class="card-component-title" :size="4">{{ title }}</Headline>
      <Headline class="card-component-subtitle" :size="5">{{ subtitle }}</Headline>
  </div>
</template>

<script>
import Headline from '@/components/Headline/Headline.component'
import Icon from '@/components/Icon/Icon.component';

export default {
  name: "Card.component",
  components: {
    Headline, Icon
  },
  props: {
    iconName: String,
    title: String,
    subtitle: String,
  },
  methods: {
    emmitClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/main.scss";

.card-component {
  width: 220px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 12px;
  text-align: center;
  background-color: white;
  padding: 20px;
  cursor: pointer;

  .card-component-title {
    height: 25px;
  }

  &:hover {
    border-color: $primary-color;
  }

  &-img {
    height: 50px;
    margin-bottom: 60px;
    margin-top: 20px;
  }

  &-title {
    margin: 0 0 15px;
  }

  &-subtitle {
    font-size: 13px;
    font-weight: normal;
    margin: 0;
  }
}
</style>
